/* --- COLORS --- */
$black: #2C2C2C;
$white: white;
$primary: #2C2C2C;
$secondary: #6D4F47;
$tertiary: #A9A9A9;
$grey: #A9A9A9;
$greyDark: #414141;
$greyLight: #F3F4F5;
$orange: #EC641A;
$error: #be2020;
$success: #a2cc52;
$yellow: #fab000;

$colors: (
    'black': $black,
    'white': white,
    'primary': $primary,
    'secondary': $secondary,
    'tertiary': $tertiary,
    'error': $error,
    'success': $success,
    'grey': $grey,
    'greyDark': $greyDark,
    'greyLight': $greyLight,
    'yellow': $yellow,
);


@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }

    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}