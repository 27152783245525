@mixin font {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

@mixin fontTitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

// l'image ayant pictureFill remplie son parent
@mixin pictureFill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
}

@mixin absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

@mixin posAbs($top, $right, $bottom, $left) {
    position: absolute;
    top: calc(#{$top} + 0px);
    right: calc(#{$right} + 0px);
    bottom: calc(#{$bottom} + 0px);
    left: calc(#{$left} + 0px);
}

@mixin transitionAll($duration: 0.25s) {
    transition: all $duration ease;
}



// --- STAGGER TRANSITION ---
@mixin stagger($time) {
    @for $i from 1 through 30 {
        &:nth-child(#{$i}) {
            transition-delay: ($time * $i);
        }
    }
}


// --- CONVERT PX TO REM ---
// Function for converting a px based font-size to rem.
@function toRem($size) {
    @if (unitless($size)) {
        $size: $size * 1px;
    }

    //Default font size on html element is 100%, equivalent to 16px;
    @return $size / 16px * 1rem;
}


// --- EASING ---
@mixin easeInOutCirc($time, $propertie: all) {
    transition: $propertie $time cubic-bezier(0.820, 0.000, 0.300, 1.000);
    /* custom */
    transition-timing-function: cubic-bezier(0.820, 0.000, 0.300, 1.000);
    /* custom */
}

@mixin easeInOut($time, $propertie: all) {
    -webkit-transition: $propertie $time cubic-bezier(0.4, 0.0, 0.2, 1);
    -moz-transition: $propertie $time cubic-bezier(0.4, 0.0, 0.2, 1);
    -o-transition: $propertie $time cubic-bezier(0.4, 0.0, 0.2, 1);
    transition: $propertie $time cubic-bezier(0.4, 0.0, 0.2, 1);

    -webkit-transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    -moz-transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    -o-transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}

@mixin easeInQuint($time, $propertie: all) {
    -webkit-transition: $propertie $time cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -moz-transition: $propertie $time cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -o-transition: $propertie $time cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition: $propertie $time cubic-bezier(0.755, 0.050, 0.855, 0.060);
    /* easeInQuint */

    -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -o-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    /* easeInQuint */
}

@mixin easeOut($time, $propertie: all) {
    -webkit-transition: $propertie $time cubic-bezier(0.0, 0.0, 0.2, 1);
    -moz-transition: $propertie $time cubic-bezier(0.0, 0.0, 0.2, 1);
    -o-transition: $propertie $time cubic-bezier(0.0, 0.0, 0.2, 1);
    transition: $propertie $time cubic-bezier(0.0, 0.0, 0.2, 1);

    -webkit-transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    -moz-transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    -o-transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

@mixin easeIn($time, $propertie: all) {
    -webkit-transition: $propertie $time cubic-bezier(0.4, 0.0, 1, 1);
    -moz-transition: $propertie $time cubic-bezier(0.4, 0.0, 1, 1);
    -o-transition: $propertie $time cubic-bezier(0.4, 0.0, 1, 1);
    transition: $propertie $time cubic-bezier(0.4, 0.0, 1, 1);

    -webkit-transition-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
    -moz-transition-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
    -o-transition-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
    transition-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
}