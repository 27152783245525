$fontXXS    : toRem(12);
$fontXS	    : toRem(14);
$fontS	    : toRem(15);
$fontM	    : toRem(25);
$fontL	    : toRem(45);
$fontXL	    : toRem(65);

$fwRegular : 400;
$fwBold    : 700;

$spacer : toRem(20);
$spacerL : toRem(40);

$screen13 : 1440px;
// Obligé de créer une variable correspondant au breakpoint xxlarge de Foundation. 
//Foundation refuse d'utiliser son breakpoint pour @include breakpoint(xxlarge down)
$xxLarge : 1400px; 

body {
    --h1-fz: #{$fontXL};
    --h2-fz: #{$fontL};
    --h3-fz: #{$fontM};
    --wp--style--block-gap: 30px; // Gutter de la grille de Gutenberg
    --wp--preset--font-size--normal : #{$fontS};
}